import React from "react";
import '../node_modules/react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';

/*const images = [
    {
      original: 'https://picsum.photos/id/1018/1000/400/',
      thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1015/1000/400/',
      thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1026/1000/400/',
      thumbnail: 'https://picsum.photos/id/1026/250/150/',
    }
  ]; */

const PREFIX_URL = '/images/';

class ImageSliderComponent extends React.Component {

    constructor() {
        super();
        this.pics = [
            {
                original: `${PREFIX_URL}1.JPG`,
                thumbnail: `${PREFIX_URL}1t.JPG`,
            },
        ].concat(this._getStaticImages());
        console.log(this.pics);
    }
    
    _getStaticImages() {
        let images = [];
        for (let i = 2; i <= 37; i++) {
            images.push({
                original: `${PREFIX_URL}${i}.JPG`,
                thumbnail:`${PREFIX_URL}${i}t.JPG`
            });
        }
        return images;
    }

    render() {
    return (
        <div className="wrapper">
            <ImageGallery items={this.pics} />
        </div>
    );
  }
}
export default ImageSliderComponent;